import Link from 'next/link';

const TopHeader = () => {
  return (
    <div className="hidden w-full justify-evenly bg-black bg-opacity-80 text-xs font-medium text-white/30 backdrop-blur-sm lg:flex">
      <a href="/" className="">
        Sell on delorand
      </a>
      <p className="">Become a Supplier</p>
      <Link href={'/help'} className="">
        Contact support
      </Link>
    </div>
  );
};

export default TopHeader;
