import {SVGProps} from 'react';

export const SearchSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#C4C5C469"
      {...props}
    >
      <g clipPath="url(#clip0_388_386)">
        <path d="M19.7561 18.5775L14.782 13.6033C16.1375 11.9455 16.804 9.83008 16.6435 7.69464C16.4831 5.55919 15.508 3.56711 13.92 2.13044C12.3319 0.69376 10.2525 -0.0775884 8.11167 -0.0240619C5.97088 0.0294646 3.93256 0.903771 2.41832 2.41801C0.904076 3.93225 0.0297698 5.97058 -0.0237567 8.11137C-0.0772832 10.2522 0.694065 12.3316 2.13074 13.9197C3.56742 15.5077 5.5595 16.4828 7.69494 16.6432C9.83038 16.8037 11.9458 16.1372 13.6036 14.7817L18.5778 19.7558C18.735 19.9076 18.9455 19.9916 19.164 19.9897C19.3825 19.9878 19.5915 19.9002 19.746 19.7457C19.9005 19.5912 19.9881 19.3822 19.99 19.1637C19.9919 18.9452 19.9079 18.7347 19.7561 18.5775ZM8.33364 15C7.0151 15 5.72617 14.609 4.62984 13.8765C3.53351 13.1439 2.67903 12.1027 2.17444 10.8846C1.66986 9.66638 1.53784 8.32594 1.79507 7.03273C2.05231 5.73953 2.68724 4.55164 3.61959 3.61929C4.55194 2.68694 5.73983 2.052 7.03304 1.79477C8.32624 1.53753 9.66669 1.66955 10.8849 2.17414C12.103 2.67872 13.1442 3.53321 13.8768 4.62953C14.6093 5.72586 15.0003 7.01479 15.0003 8.33333C14.9983 10.1008 14.2953 11.7954 13.0455 13.0452C11.7957 14.295 10.1011 14.998 8.33364 15Z" />
      </g>
      <defs>
        <clipPath id="clip0_388_386">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CartSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4222 19.8203C7.8442 19.8203 8.1872 20.1633 8.1872 20.5853C8.1872 21.0073 7.8442 21.3493 7.4222 21.3493C7.0002 21.3493 6.6582 21.0073 6.6582 20.5853C6.6582 20.1633 7.0002 19.8203 7.4222 19.8203Z"
      stroke="#D9D9D9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6752 19.8203C19.0972 19.8203 19.4402 20.1633 19.4402 20.5853C19.4402 21.0073 19.0972 21.3493 18.6752 21.3493C18.2532 21.3493 17.9102 21.0073 17.9102 20.5853C17.9102 20.1633 18.2532 19.8203 18.6752 19.8203Z"
      stroke="#D9D9D9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 3.25L4.83 3.61L5.793 15.083C5.871 16.018 6.652 16.736 7.59 16.736H18.502C19.398 16.736 20.158 16.078 20.287 15.19L21.236 8.632C21.353 7.823 20.726 7.099 19.909 7.099H5.164"
      stroke="#D9D9D9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.126 10.795H16.899"
      stroke="#D9D9D9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
